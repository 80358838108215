

@media only screen and (min-width: 768px) and (max-width: 920px)  {

    .m-l-impact{
        margin-left: 198px !important;
    }
    .m-r-impact{
        margin-left: 380px !important;
    }
    .m-l-about{
        margin-left: 345px;
    }
    .left-pic-main-dots-about-cat {
        width: 285px !important;
        height: 259px !important;
        position: absolute !important;
        top: 114px !important;
        left: -28px !important;
        z-index: 1;
    }
   
    .right-pic-main-dots-about-above {
        height: 155px !important;
        width: 176px !important;
        position: absolute !important;
        top:-22px !important;
        left: 105px !important;
    }
   
    .right-pic-main-dots-about-belove {
        height: 174px !important;
        width: 251px !important;
        position: absolute !important;
        top: 231px !important;
        // right: 135px !important;
        left:-98px !important;
    }
    .m-t-leader{
        margin-top: 229px !important;
    }

    .m-t-in-fl{
    margin-top: 40px !important;
    display: flex !important;
    flex-direction: column !important;
    }
    .m-t-first{
         margin-left: 216px !important;
    }
    .m-l-scure-about{
    margin-left: 342px !important;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    }
    .m-l-scure-about-2{
        margin-left: 390px !important;
    }
    .m-l-about-2{
        margin-left: 441px !important;
    }
    .table-w-lg{
        width: 760px !important;
    }
    .m-l-mny{
        margin-left: 365px !important;
    }
    .m-l-mny2{
        margin-left: 385px !important;
    }
    .m-t-mny{
       margin-top: -101px !important;
    }
    .card-top-part {
        height: 249px !important;
    }
    .m-l-scure-about-2{
        margin-left: 282px !important;
    }
    #web-award-carosoul{
        display: block;
    }
    .award-pic-size{
        width: 223px !important;
    height: 210px !important;
    margin-bottom: 20px;
     
    }
    #web-section4-corsol{
        display: block;
    }
    .about-card-shadow2 {
        width: 237px !important;
        height: 240px !important;
        position: absolute !important;
        top: 144px !important;
        left:-38px !important;
    
    }
    
        .card-shadow-imp2 {
            width: 286px !important;
            height: 247px !important;
            right: 153px !important;
            position: absolute !important;
            z-index: -1 !important;
            top: 32px !important;
        }
    
    .left-pic-main-secure {
        height: 311px;
        width: 398px;
        position: absolute;
        top: 0;
        right: -96px !important;
        z-index: 2 !important;
    }
    .secure-shadow {
        position: absolute;
        width: 386px;
        height: 290px;
        top: -8px;
        /* right: 0; */
        right: -71px !important;
    }
    .left-pic-main-dots-secure {
        height: 217px;
        width: 217px;
        position: absolute;
        top: -32px;
        /* right: 122px; */
        z-index: 1;
        right: -122px !important;
    }
    .right-pic-main-about {
    
        right: -76px !important;
        z-index: 2;
    }
    .about-card-shadow {
      
        right: -55px !important;
    }
   
        .left-pic-main-dots-about {
            /* height: 235px; */
            max-width: 235px;
            position: absolute;
            top: -35px !important;
            /* right: 130px; */
            z-index: 1;
            left: 180px !important;
            width: 100% !important;
        }
    
    .right-pic-main {
        width: 115% !important;
      
    }
    .card-shadow-imp {
      
        max-height: 200px !important;
    }
    .right-pic-main-dots {
        max-width: 178px !important;
        width: 100% !important;
    }
    .solidc {
        position: absolute;
        /* left: 416px; */
        /* top: 0; */
        z-index: 2;
        left: 372px !important;
        bottom: 6px !important;
        max-width: 46px !important;
    }
    .top-circle {
        max-width: 29px !important;
        top: 19rem !important;
        left: -2.5rem
    rem
     !important;
        height: 31px;
    }
    .hb-content-matrix {
        right: 48% !important;
    }
    .hb-left-header-main {
        font-size: 26px !important;
    }
    .card-text-benifits {
       
        font: normal normal 13px/28px Roboto !important;
        letter-spacing: 0px;
        
    }
    .solidb {
    
        left: 38px !important;
    
    }
    .hollowa {
       
        left: 0 !important;
    }

}