.solid1 {
  max-width: 46px;
  /* height: 59px; */
  position: absolute;
  top: -76px;
  left: 0px;
  width: 100%;
}
.hollow1 {
  max-width: 46px;
  /* height: 59px; */
  position: absolute;
  top: 0px;
  left: -67px;
  width: 100%;
}
.circle1 {
  max-width: 44px;
  height: 44px;
  width: 100%;
  position: absolute;
  right: -3rem;
  top: 18rem;
}

.ps-sld {
  position: absolute;
  top: -208px;
  left: 31px;
}
.ps-hlw {
  position: absolute;
  top: -296px;
  left: 43px;
}
.ps-rnd {
  position: absolute;
  top: 118px;
  left: 542px;
}

.circledot {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: -55px;
  left: 58px;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .circledot {
    width: 90%;
    position: absolute;
    z-index: 1;
    top: -31px !important;
    left: 69px !important;
    z-index: -1;
  }
  .hollow1 {
    max-width: 29px !important;
    top: 7rem !important;
    left: -2.5rem !important;
  }
  .solid1 {
    max-width: 23px !important;
    top: 9.5rem !important;
    left: -1.5rem !important;
  }
  
    .circle1{
        max-width: 29px !important;
        top: 19rem !important;
        left: -1.5rem !important;

       height: 31px;
     }
 
}

@media screen and (max-width: 912px) and (min-width:425px){
    .hb-content-matrix{
        bottom: 174px !important;
    }
    .hb-left-header-main {
        font-size: 35px !important;
        line-height: 36px !important;
    }
    .solid1{
        top: -103px !important;
        left: -67px !important;
    }
}