/**
| media qurey.
| $mediaType: 
| 1 = "max-widht"
| 2 = "min-width"
*/
@mixin MQ($size, $mediaType: 1) {
    $mediaType: if( $mediaType == 1, 'max-width', 'min-width');
    @if $size == sm {
      @media only screen and ($mediaType: 767px) {
        @content;
      }
    } @else if $size == md {
      @media only screen and ($mediaType: 992px) {
        @content;
      }
    } @else if $size == lg {
      @media only screen and ($mediaType: 1024px) {
        @content;
      }
    } @else {
      @media only screen and ($mediaType: $size) {
        @content;
      }
    }
  }
  