@import "../../mixins.scss";

.text{
    text-align: center;
    width: 100%;
     background-image: url('./images/Mask\ Group\ 152.png');
    background-size: cover ;
}

.sectow-card-content{
    font-family: "Roboto";
    font-size: 18px;
}
.icone-image{
    width: 55px;
    height: 55px;
    filter: brightness(0) invert(1);
}


.ig-stats-wrapper {
    @include MQ(sm) {
        flex-direction: column;
    }
}

.second-margin-bt{

    margin-bottom: 20px;
}