.bg{
    
    background-color:#1E75BB !important;
    text-align: center;
    padding-bottom: 62px !important;
}

.row-flex{
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
  padding-top: 20px;
}
.card{
    margin-top: 20px;
    width: 350px;
    height: 100px !important;
    border-radius: 10px;
    margin: 20px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    
}
.txt-l{
    text-align: left;
}

.bg2{
   
    height: 200px;
    text-align: left;
    position: relative;
}
.posi{
    position: relative;
    bottom: 93px;
}
button{
    width: 160px;
height: 52px;
background: #1E75BB 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 8px;
}

.image-secure-benifits{
    height: 50px;
}


.heading-first-benifits{
   font-family: "Roboto";

   font-size:42px;
    color: #FFFFFF;
    opacity: 1;
}
.small-heading-prgf{
    text-align: center;
    font-family: "Roboto";

   font-size:20px;
    color: #FFFFFF;
    opacity: 1;
}

.card-text-benifits{
    text-align: left;
 font: normal normal  16px/28px Roboto;
 letter-spacing: 0px;
 color: #393939;
}


.heading-first-benifits-m{
    font-family: "Roboto";
 
    font-size:20px !important;
    color: #FFFFFF;
     opacity: 1;
 }

 .small-heading-prgf-m{
    text-align: center;
    font-family: "Roboto";

   font-size:15px;
    color: #FFFFFF;
    opacity: 1;
}

.hb-btn11{
    background-color: #1e75bb !important; 
    color: #FFFF !important;
 
}

@media screen and (min-width: 768px) {
    #mobile-benifits{
     display: none;
    }
    .margine-top-benifits{
        position: relative;
        top:20px
    }

}

@media screen and (max-width:768px) {
    #web-benifits{
     display: none!important;   

 }
  .card-space{
    justify-content: space-around !important;
  }
}

.button-set-below-flex{
    display: flex;
    justify-self: center !important;
    align-items: center;
}

#mobile-benifits{
      
    .carousel-indicators {
        bottom: -30px; 
        position: absolute;
        bottom: -42px;
    

        [data-bs-target] {
            box-shadow: none;
            width: 20px;
            height: 5px;
             border-radius: 5px;
            background-color: grey;
            border: 1px solid grey;
        }

        .active {
            background-color: #FFFFFF;
            border: 1px solid #FFFFFF;
        }
    }
}