.first-left-section{
    background-color: #ffff;
    height: 460px;
    text-align:left;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.second-right-section{
    
    height: 460px;
}


.img-first-secure-main{
    width: 277px;
    height: 327px;
    position: relative;
    top: 96px;
    left: 116px;
    z-index: 2;
}

.img-first-secure-dots{
    width: 250px;
    height: 327px;
    position: relative;
    top: -325px;
    left: 173px;
    z-index: 1;
}


.headindtext-first{
    text-align: left;
    font-family: "Roboto";
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
}


.paragrap-first{
    text-align: left;
    font-family: "Roboto";
    font-size: 20px;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
}


// @media screen and (min-width: 768px) {
//    .hide-p{
//     display: none;
//    }
//    .headindtext-first{
//     text-align: center;
//     font-family: "Roboto";
//     font-size: 38px;
//     font-weight: bold;
//     letter-spacing: 0px;
//     color: #393939;
//     opacity: 1;

//     }

//     .img-first-secure-main-mobile{
//         width: 268px;
//         height: 327px;
//         position: relative;
//         top: 80px;
//         left: 55px;
//         z-index: 2;
//     }
//     .second-right-section{
    
//         height: 175px;
//     }



// }

.hb-overlay1{
    background-color: #FFFF !important;
}

.hb-btn1{
     background-color: #1e75bb !important; 
     color: #FFFF !important;
    
}
.hb-content-matrix {
    background-color: #fff;
    border-radius: 12px;
    bottom: 40px;
    box-shadow: 0 3px 6px rgba(0,0,0,.161);
    min-width: 172px;
    padding: 13px;
    position: absolute;
    right: 77%;
    text-align: center;
    z-index: 1;
    border-width: 3px;
    border-color: white;
    border-style: solid;
}

.hb-content-matrix-header1{
    color: #FFFF !important;
  
}
.hb-content-matrix-content1{
 color: #FFFF !important;
}










@media screen and (max-width:768px) {
 .hideInMobile{
     display: none!important;   
 }
}