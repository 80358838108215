.award-pic-size{
    width: 259px;
height: 265px;
margin-bottom: 20px;
 
}

.flex-award-center{
    justify-content: center;
    align-items: center;
}

// .text-align-award-content{
//     text-align: center;
// }

.award-heading{
    font-family: "Roboto";
    font-size: 42px;
    font-weight: bold;
letter-spacing: 0px;
color: #393939;
text-transform: capitalize;
opacity: 1;
text-align: center;
margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
    #mobile-award-carosoul{
     display: none;
    }

}

@media screen and (max-width:768px) {
 #web-award-carosoul{
     display: none!important;   
 }
 .award-heading{
    font-family: "Roboto";
    font-size: 24px !important;
    font-weight: bold !important;
letter-spacing: 0px;
color: #393939;
text-transform: capitalize;
opacity: 1;
text-align: center;
margin-bottom: 40px;
}
}

.m-awards-carousel {
    .award-carousel-content {
        min-height: 150px;
    }
    
}

.web-award-m{
               
    .carousel-control-next-icon {
        background-color: #ffff !important;
        background-image: url("./images/Group\ 2627.png") !important;
        left: -299px !important;
        position: absolute !important;
        bottom: -6px !important;
        width: 2.5rem !important;
        height: 2.5rem !important;
    
        
      }
    .carousel-control-prev-icon{
        background-color: #ffff !important;
        background-image: url("./images/Group\ 2626.png") !important;
        position: absolute;
        bottom: -6px;
        left: 426px;
        width: 2.5rem !important;
        height: 2.5rem !important;
    
       
    }
}


.mobile-award-m{
               
    .carousel-control-next-icon {
        background-color: #ffff !important;
        background-image: url("./images/Group\ 2627.png") !important;
        left: -117px !important;
        position: absolute !important;
        bottom: 2px !important;
        width: 2.5rem !important;
        height: 2.5rem !important;
    
        
      }
    .carousel-control-prev-icon{
        background-color: #ffff !important;
        background-image: url("./images/Group\ 2626.png") !important;
        position: absolute;
        bottom: 2px;
        left: 118px;
        width: 2.5rem !important;
        height: 2.5rem !important;
    
       
    }
}
