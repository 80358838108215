@import "../../mixins.scss";

.text-align-home {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home-section-images {
  max-height: 550px;
  min-height: 550px;

  @include MQ(sm) {
    min-height: 350px;
  }
}

.header-section-first {
  text-align: left;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #393939;
  opacity: 1;

  @include MQ(sm) {
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
  }
}

.header-paragrap-home {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #393939;
}

.header-paragrap-home-ul-li {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #393939;
  opacity: 1;
  margin-left: -14px;
}

.solidage {
  width: 100%;
  max-width: 3vw;
  position: absolute;
  top: 201px;
  right: 479px;
}
.circleage {
  width: 100%;
  max-width: 3vw;
  position: absolute;
  bottom: 15%;
  z-index: 2;
  left: 333px;
}
@media screen and (max-width: 768px) {
  .header-paragrap-home {
    text-align: center;
    font-size: 14px;
    font-family: "Roboto";
    letter-spacing: 0px;
    color: #393939;
  }
  .card-shadow {
    width: 160px !important;
    height: 168px !important;
    /* right: 72px; */
    position: absolute !important;
    z-index: -1 !important;
    top: 187px !important;
    left: 30px !important;
  }
  .solidage {
    max-width: 7vw !important;
    top: 41%;
    right: 79%;
  }
  .circleage{
    max-width: 7vw !important;
    right: 226px !important;
    left: 212px;
  }
}

.pic-second-home-top {
  width: 400px;
  height: 400px;
  position: absolute;
  right: 50px;
  object-fit: cover;
  border-radius: 12px;

  @include MQ(sm) {
    width: 250px;
    height: 250px;
    right: 20px;
  }
}

.pic-second-home-bottom {
  width: 285px;
  height: 285px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 12px;

  @include MQ(sm) {
    width: 165px;
    height: 165px;
    left: 20px;
  }
}

.dots-black-mesh {
  width: 250px;
  height: 250px;
  position: absolute;
  bottom: -30px;
  left: 65px;
  z-index: -1;

  @include MQ(sm) {
    width: 160px;
    height: 160px;
    top: -100px;
    left: unset;
    right: 130px;
    bottom: unset;
    top: -20px;
  }
}

.margine-top-upper-bottom {
  margin-top: 55px;
  margin-bottom: 90px;
}

.solid {
  width: 46px;
  height: 46px;
}
.hollow {
  width: 59px;
  height: 59px;
}
.circle {
  width: 44px;
  height: 44px;
}

.h-p-round {
  position: absolute;
  bottom: 60px;
  right: 256px;
}
.h-p-solid {
  position: absolute;
  top: 159px;
  left: 125px;
}

.card-shadow {
  width: 388px;
  height: 279px;
  right: 72px;
  position: absolute;
  z-index: -1;
  top: -10px;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .circleage {
    right: 25%;
  }
  .solidage {
    left: -12px;
  }
}
