* {
  margin: 0;
  padding: 0;
}

footer {
  background-color: #013c5e;
  color: #ffffff;
  font-size: 18px;
}

.flex-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (min-width: 768px) {
  .text-center-footer {
    text-align: left;
  }
  
}

@media screen and (max-width: 768px) {
  .text-center-footer {
    text-align: center;
  }
  .mobile-block{
    display: none !important;
  }
  .pd{
    padding-top: 20px;
  }
}


