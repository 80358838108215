table {
    border-collapse: collapse;
    width: 50%;
    border-radius: 10px;
    padding: 20px !important;
}
  
th, td {
text-align: left;
border-right:5px solid white;
text-align: center;
width: 256px;
height: 56px;
}
  
tr:nth-child(even){background-color:#6DCCEF}

th {
background-color: #1E75BB;
color: white;
text-align: center !important;
}

button{
    top: 2652px;
    left: 640px;
    width: 160px;
    height: 52px;
    background: #1E75BB 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    color: aliceblue;
}

.heading-table-money{
    font-family: "Roboto";
    font-size: 42px;
  color: #393939;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.second-headline-table{
    font-family: "Roboto";
    font-size: 16px;
  color: #393939;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.con-p-instl{
    padding-top: 30px;
}


table{
    padding-bottom: 20px;
}

.flex-table{
    padding-bottom: 20px;
}

.hb-btn1{
    background-color: #1e75bb !important; 
    color: #FFFF !important;
}      
        
.flex-instalment{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40PX;
    margin-bottom: 40PX;
}
.table-w-lg{
    width: 100%;
}

@media screen and (min-width: 760px) {
    .mobile {
        display: none;
    }

}

@media screen and (max-width:500px) {
    .web {
        display: none !important;
    }
}
