#carepalAbout {
  .text {
    text-align: center;
  }

  .col-width-clr {
    height: 350px;
    background-color: #ffffff;
  }

  .right-pic-main-money {
    max-height: 320px;
    max-width: 520px;
    position: absolute;

    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .left-pic-main-dots-money {
    height: 197px;
    width: 197px;
    position: absolute;
    top: -33px;
    /* right: 227px; */
    z-index: 1;
    left: 225px;
  }

  .left-pic-main-money {
    max-height: 320px;
    max-width: 520px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .right-pic-main-dots-money {
    height: 251px;
    width: 251px;
    position: absolute;
    top: -37px;
    right: 32px;
  }

  .align-flex-paragrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .headindtext-second-money {
    text-align: left;
    font-family: "Roboto";
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
  }

  .paragrap-second-money {
    text-align: left;
    font-family: "Roboto";
    font-size: 16px;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
  }

  .container-detail-size {
    
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .about-shadow {
    position: absolute;
    width: 386px;
    height: 290px;
    top: -10px;
    /* right: 0; */
    left: -10px;
  }

  .about-shadow2 {
    position: absolute;
    width: 341px;
    height: 274px;
    top: -9px;
    right: 64px;
  }
  @media screen and (max-width: 768px) {
    .headindtext-second-money {
      font-size: 24px !important;

      color: #393939 !important;
    }

  
    .right-pic-main-dots-money {
      right: 14px !important;
      width: 170px !important;
      height: 170px !important;
      top: 53px !important;
    }
    .col-width-clr {
      height: 261px !important;
      position: relative !important;
      z-index: 3 !important;
    }

    .heading-table-money {
      font-size: 24px !important;
    }
    .about-shadow {
      position: absolute;
      width: 233px;
      height: 192px;
      top: -6px;
      /* right: 0; */
      left: 58px;
    }
    .about-shadow2 {
      position: absolute;
      width: 249px;
      height: 192px;
      top: 72px;
      right: 32px;
    }
  }

  .solid-a {
    position: absolute;
    z-index: 3;
    bottom: 38px;
    left: 416px;
    width: 100%;
    max-width: 45px;
  }

  //  .solid-b{
  //   width: 100%;
  //   max-width: 40px;
  //   position: absolute;
  //   left: 148px;
  //   bottom: 126px;
  //  }
  .solid-b {
    width: 100%;
    max-width: 40px;
    position: absolute;
    left: 167px;
    /* bottom: 1px; */
    top: 82px;
  }
  .hollow-a {
    width: 100%;
    max-width: 30px;
    position: absolute;
    bottom: 176px;
    left: 153px;
  }

  @media screen and (min-width: 768px) {
    .m-t {
      margin-top: 38px !important ;
    }
  }
}

// .headindtext-second {
//   text-align: left;
//   font-family: "Roboto";
//   font-size: 42px;
//   font-weight: bold;
//   letter-spacing: 0px;
//   color: #393939;
//   opacity: 1;
// }

// .paragrap-second {
//   text-align: left;
//   font-family: "Roboto";
//   font-size: 16px;
//   letter-spacing: 0px;
//   color: #393939;
//   opacity: 1;
// }

// .text {
//   text-align: center;
// }

// .right-pic-main-secure {
//   height: 284px;
//   width: 360px;
//   position: absolute;
//   top: 0;
//   left: 65px;
//   z-index: 2;
// }

// .left-pic-main-dots-secure {
//   height: 217px;
//   width: 217px;
//   position: absolute;
//   top: -32px;
//   /* right: 122px; */
//   z-index: 1;
//   right: 34px;
// }

// .left-pic-main-secure {
//   height: 311px;
//   width: 398px;
//   position: absolute;
//   top: 0;
//   right: 65px;
//   z-index: 2;
// }

// .right-pic-main-dots-secure {
//   height: 251px;
//   width: 251px;
//   position: absolute;
//   top: -38px;
//   left: 24px;
//   z-index: 1;
// }

// .icon-size-a {
//   width: 40px;
// }

// .card-design-style {
//   height: 300px;
// }
// .height-block-1 {
//   height: 300px;
// }
// .secure-shadow {
//   position: absolute;
//   width: 386px;
//   height: 290px;
//   top: -8px;
//   /* right: 0; */
//   right: 56px;
// }

// .ml-t-about-2 {
//   margin-top: 69px !important ;
// }
// .secure-shadow2 {
//   position: absolute;
//   width: 349px;
//   height: 274px;
//   top: -9px;
//   left: 56px;
// }
// @media screen and (max-width: 768px) {
//   .card-design-style {
//     height: 271px !important;
//   }
//   .height-block-1 {
//     height: 0;
//   }
//   .m-top {
//     margin-top: 216px !important;
//   }
//   .m-bottom {
//     margin-top: 46px !important;
//   }
//   .headindtext-second {
//     font-size: 24px !important;
//   }
//   .secure-shadow {
//     position: absolute;
//     width: 249px;
//     height: 192px;
//     top: -63px !important;
//     left: 44px !important;
//   }
//   .secure-shadow2 {
//     position: absolute;
//     width: 249px;
//     height: 192px;
//     top: 64px;
//     left: 70px;
//   }
// }


@media screen and (max-width: 540px) {
  .right-pic-main-money {
   
    /* top: 0; */
    bottom: -19px !important;
  
}
.left-pic-main-money {
 
  top: -32px !important;
  
}
}