// .scroll-to-top {
//     position: fixed;
//     bottom: 50px;
//     right: 50px;
//     z-index: 999;
//   }
  
//   .scroll-btn {
//     background-color: #3498db;
//     border: none;
//     border-radius: 50%;
//     color: #fff;
//     cursor: pointer;
//     font-size: 1.5rem;
//     height: 60px;
//     outline: none;
//     width: 60px;
//     transition: background-color 0.3s ease;
  
//     &:hover {
//       background-color: #2980b9;
//     }
//   }
  
//   .progress-bar {
//     height: 5px;
//     background-color: #e5e5e5;
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     z-index: 998;
//   }

#progress {
    position: fixed;
    bottom: 20px;
    right: 30px;
    height: 45px;
    width: 45px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 4;
  }
  #progress-value {
    display: block;
    height: calc(100% - 11px);
    width: calc(100% - 11px);
    background-color:rgb(28, 117, 188);
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 35px;
    border: 5px solid transparent
   
  }
  .progress-img{
    position: absolute;
    max-width: 23px;
  }