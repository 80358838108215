#impsection3 {
  .right-pic-main {
    max-height: 320px;
    max-width: 520px;
    position: absolute;
    top: -12px;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .right-pic-main-dots {
    position: absolute !important;
    top: 0 !important;
    /* left: 40px !important; */
    left: 28px;
    z-index: -1;
  }

  .left-pic-main {
    max-height: 320px;
    max-width: 520px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .left-pic-main-dots {
    height: 221px;
    width: 320px;
    position: absolute;
    top: 8px;
    right: 80px;
    z-index: 1;
  }

  .sectow-card-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .maincontaint-heading-bolg {
    text-align: left;
    font: normal normal medium;
    font-family: "Roboto";
    font-size: 42px;

    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
  }

  .maincontaint-paragrap-bolg {
    text-align: left;
    font: normal normal medium;
    font-family: "Roboto";
    font-size: 16px;

    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
  }

  .container-height-im {
    height: 400px;
  }
  .container-height-im2 {
    height: 400px;
  }

  .card-shadow-imp {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 56px;
    top: 27px;
    z-index: -1;
    max-width: 462px;
    max-height: 270px;
  }
  .card-shadow-imp2 {
    max-width: 426px;
    /* height: 345px; */
    right: 154px;
    position: absolute;
    z-index: -1;
    top: 32px;
    width: 100%;
  }
  .about-shadow2 {
    position: absolute;
    width: 341px;
    height: 274px;
    top: -9px;
    right: 64px;
  }

  .solid-a {
    position: absolute;
    z-index: 3;
    bottom: 65px;
    right: 93px;
    width: 100%;
    max-width: 40px;
  }

  .solid-b {
    width: 100%;
    max-width: 44px;
    position: absolute;
    left: 2px;
    top: 298px;
  }
  .hollow-a {
    width: 100%;
    max-width: 30px;
    position: absolute;
    bottom: 104px;
    left: -12px;
  }

  @media screen and (min-width: 768px) {
    #mobile-testimonial {
      display: none;
    }
  }
  .width-icone-size {
    width: 40px;
  }

  @media screen and (max-width: 768px) {
    .container-height-im2 {
      height: 280px !important;
    }
    .left-pic-main {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
    }
    .left-pic-main-dots {
      height: 154px !important;
      width: 154px !important;
      position: absolute !important;
      top: 17px !important;
      right: 41px !important;
      z-index: 1 !important;
    }

    .right-pic-main {

    padding-bottom: 1rem !important;
    }
    .right-pic-main-dots {
      position: absolute !important;
      top: 19px !important;
      left: 47px !important;
      width: 154px !important;
      height: 154px !important;
      z-index: -1 !important;
    }
    .maincontaint-heading-bolg {
      font-size: 24px !important;
    }
    .container-height-im {
      height: 270px !important;
    }
    .container-height-im2 {
      height: 280px !important;
    }
    .card-shadow-imp {
      width: 246px;
      height: 196px;
      left: 80px !important;
      position: absolute;
      z-index: -1;
      top: 35px;
    }
    .card-shadow-imp2 {
      width: 243px !important;
      height: 212px !important;
      right: 78px !important;
      position: absolute;
      z-index: -1;
      top: 35px !important;
    }
    .hollow-a {
      width: 100%;
      max-width: 25px;
      position: absolute;
      bottom: 58px;
      left: 3px;
    }
    .solid-b {
      width: 100%;
      max-width: 34px;
      position: absolute;
      left: 9px;
      top: 218px;
    }
    .solid-a {
      position: absolute;
      z-index: 3;
      bottom: 42px;
      right: 13px;
      width: 100%;
      max-width: 35px;
  }
  }

//   @media only screen and (max-width: 1200px) and (min-width: 1024px) {
//     .left-pic-main {
//       max-height: 331px;
//       max-width: 317px;
//       position: absolute;
//       top: 57px;
//       right: 104px;
//       width: 100%;
//       z-index: 2;
//     }
//     .card-shadow-imp2 {
//       max-width: 325px;
//       /* max-height: 379px; */
//       right: 105px;
//       position: absolute;
//       z-index: -1;
//       top: 50px;
//       width: 100%;
//     }
//     .left-pic-main-dots {
//       /* height: 208px; */
//       max-width: 204px;
//       position: absolute;
//       top: 18px;
//       right: 79px;
//       z-index: 1;
//       width: 100%;
//     }
//     .card-shadow-imp {
//       max-height: 238px;
//     }
//     .maincontaint-paragrap-bolg {
//       font-size: 14px;
//     }
//     .maincontaint-heading-bolg {
//       font-size: 34px;
//     }
//     .right-pic-main {
//       top: 64px;
//     }
//     .card-shadow-imp {
//       top: 56px;
//     }
//     .right-pic-main-dots {
//       top: 31px !important;
//       left: 29px;
//       z-index: -1;
//       width: 100%;
//       max-width: 184px;
//     }
//   }
 }

