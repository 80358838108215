.row-flex{
   display: flex;

}
.inner-right{
 text-align: center;
}
.inner-left{
  text-align: center;
}

.img-icon{
  width: 87px;
  
  margin-bottom: 18px;
}

.padding-for-succuse{
  padding-top: 27px;
    padding-bottom: 30px;
}

.text-categ-box{
  font-family: "Roboto";
  font-size: 16px;
  
}
.heading-main-tr{
  font-family: "Roboto";
    font-size: 42px;
    letter-spacing: 0px;
    color: #393939;
    text-transform: capitalize;
    opacity: 1;
}
.fl-defn{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  
}

.margin-l{
  /* margin-left: 40px; */
}

@media screen and (max-width : 768px) {
   .mr-la-sec{
      margin-right: 24px;
   }
   .px-20{
    font-size: 20px !important;
   }
   .px-24{
    font-size: 24px!important;
   }
   .fl-defn{
    display: flex !important;
    flex-direction: column !important;
    /* justify-content: center !important;
    align-items: center !important; */
  }
  .margin-l{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    text-align: left;
  }
  
}