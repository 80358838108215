.bg{
    background-color: azure;
    text-align: center;
 
}

.row-flex{
 display: flex;
 flex-direction: row !important;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}


.card-test-impact{

    width: 165px;
    height: 93px !important;
    border-radius: 10px;
    box-shadow: 2px 10px 10px #565252;
    background-color: #FFFF;
    margin: 20px;
}

.card-top-part-impact{
    height: 101px;
   

}
.card-bottom-part-impact{
    height: 47px;
    background-color: #8ad0ee;
    border-radius:0px 0px 10px 10px;
}

.icons{
    width: 48px;
    height: 46px;
    margin-top: 10px;
    // margin-left: 54px;
    margin-bottom: 10px;
}

.icons-mobile{
    width: 48px;
    height: 46px;
    margin-top: 10px;
    margin-left: 57px;
    margin-bottom: 10px;
}

.card-text-treatment-mobile {
    font-family: "Roboto";
    font-size: 14px;
    margin-top: -5px;
    text-align: center;
    background-color:#52BAE6;
    border-radius: 0 0 5px 5px;
}


.header-content-section4{
    font: normal normal medium 42px/50px Roboto;
    font-family: "Roboto";
    font-size: 42px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
  
}

.colorbg{
    background-color: #1E75BB ;
    padding-top: 30px;
    padding-bottom: 20px;
}

.text-categ-box{
    font-family: "Roboto";
    font-size: 18px;
    
}
.card-test-about-mobile{

    width: 165px !important;
    height: 102px !important;
    border-radius: 10px;
    box-shadow: 1px 1px  1px #d2c8c8;
    background-color: #FFFF;
    margin: 20px;
    }

    .card-bottom-part-money-l {
        height: 33px;
        border-radius: 0px 0px 10px 10px;
        background-color: #52BAE6;
        width: 165px;
        height: 40px;
    }
   

@media screen and (min-width: 768px) {
    #mobile-section4-corsol{
     display: none;
    }

}

@media screen and (max-width:768px) {
    #web-section4-corsol{
     display: none!important;   
 }

}

.botum-bg-color{
    height: 77px;
    background: #52BAE6;
    /* width: auto; */
    padding: 10px;
    width: 164px;
    border-radius: 0px 0px 10px 10px;
}


.mobile-croud {
    .carousel-indicators {
        bottom: -30px;

        [data-bs-target] {
            box-shadow: none;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: grey;
            border: 1px solid grey;
        }

        .active {
            background-color: #FFFFFF;
            border: 1px solid #FFFFFF;
        }
    }
}




