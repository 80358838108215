#carepalMoneyabout {
  .text {
    text-align: center;
  }

  .col-width-clr {
    height: 350px;
    background-color: #1c75bc;
  }

  .right-pic-main-money {
    max-height: 320px;
    max-width: 520px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .left-pic-main-dots-money {
    height: 197px;
    width: 197px;
    position: absolute;
    top: -33px;
    /* right: 227px; */
    z-index: 1;
    left: 225px;
  }

  .left-pic-main-money {
    max-height: 320px;
    max-width: 520px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .right-pic-main-dots-money {
    height: 251px;
    width: 251px;
    position: absolute;
    top: -37px;
    right: 32px;
  }

  .align-flex-paragrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .headindtext-second-money {
    text-align: left;
    font-family: "Roboto";
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffff;
    opacity: 1;
  }

  .paragrap-second-money {
    text-align: left;
    font-family: "Roboto";
    font-size: 16px;
    letter-spacing: 0px;
    color: #ffff;
    opacity: 1;
  }

  .container-detail-size {
    background-color: #1c75bc;
    padding-top: 73px;
    padding-bottom: 40px;
  }
  .about-shadow {
    position: absolute;
    width: 386px;
    height: 290px;
    top: -10px;
    /* right: 0; */
    left: -10px;
  }

  .about-shadow2 {
    position: absolute;
    width: 341px;
    height: 274px;
    top: -9px;
    right: 64px;
  }

  .solid-a {
    position: absolute;
    z-index: 3;
    bottom: 38px;
    left: 416px;
    width: 100%;
    max-width: 45px;
  }

  .solid-b {
    width: 100%;
    max-width: 40px;
    position: absolute;
    right: 464px;
    /* bottom: 1px; */
    top: 82px;
    z-index: 7;
  }
  .hollow-a {
    width: 100%;
    max-width: 30px;
    position: absolute;
    bottom: 284px;
    right: 495px !important;
    z-index: 2;
  }

  @media screen and (max-width: 768px) {
    .headindtext-second-money {
      font-size: 24px !important;

      color: #ffff !important;
    }

    // .right-pic-main-money {
    //   right: 38px !important;
    //   width: 250px !important;
    //   height: 200px !important;
    //   top: 78px !important;
    // }
    .right-pic-main-dots-money {
      right: 13px !important;
      width: 170px !important;
      height: 170px !important;
      top: 51px !important;
    }
    .col-width-clr {
      height: 261px !important;
      position: relative !important;
      z-index: 3 !important;
    }

    .heading-table-money {
      font-size: 24px !important;
    }
    .about-shadow {
      position: absolute;
      width: 233px;
      height: 192px;
      top: -6px;
      /* right: 0; */
      left: 58px;
    }
    .about-shadow2 {
      position: absolute;
      width: 249px;
      height: 192px;
      top: 72px;
      right: 32px;
    }

    .hollow-a {
      width: 100%;
      max-width: 30px;
      position: absolute;
      bottom: 119px !important;
      right: 306px !important;
      z-index: 2 !important;
    }
    .solid-b {
      width: 100%;
      max-width: 37px;
      position: absolute;
      right: 291px !important;
      /* bottom: 1px; */
      top: 151px !important;
      z-index: 7;
    }
  }

  @media screen and (min-width: 768px) {
    .m-t {
      margin-top: 38px !important ;
    }
  }
}
