.navbar-toggler:focus{
    box-shadow: 0 0 0 0!important;
}

.nav-l-shadowkkk::after{

    content: "";
    position: absolute;
    width: 100%;
    height: 0.175rem;
    left: 0;
    bottom: 0;
    background-color: black;
}

a:hover{
    // color: #1E75BB !important;
}



@media screen and (max-width: 760px) {
    .aboutus:hover{
        color: #1E75BB !important;
    }
    .dropdown-menu.show{
    border: none !important;
    }
   
 }