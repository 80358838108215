.leader-border{
    box-shadow: 2px 2px 5px 2px #aea7a7;
    border-color: black;
    margin-bottom: 90px;
    border-radius: 10px;
    margin-left: 2px!important;
    margin-right: 5px !important;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
}
.m-top-l{
    margin-top: 30px;
}
.readMoreClassName{
    color: blue;
    position: relative;
    right: -42px;
}
.readMoreClassName:hover{
    color: blue;
}

.small-heading-leader{
  
 font: 'Roboto';
 font-size: 20px;
letter-spacing: 0px;
font-weight: bolder;

}
.small-heading-second-leader{
    font:"Roboto";
    font-size: 20px ;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
  
}
.paragrap-pic-leader{
    font:"Roboto";
    font-size: 16px ;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
}


.small-heading-second{
    font:"italic";
    font-size: 42px ;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
    font-weight: bolder !important;
    line-height: normal;
    font-weight: bold;
    text-align: center;
}


.anchor-tag-read{
    position: relative;
}
.leader-font-size{
    font:normal normal normal 16px/28px Roboto;
    color: #393939;
}

@media screen and (max-width : 768px) {
     .m-top-l{
        margin-top: 10px !important;
     }
    .small-heading-leader{
        text-align: center;
    }
    .award-pic-size {
        width: 166px;
        height: 163px;
        margin-bottom: 18px;
    }
    .small-heading-second-leader{
        font:"Roboto" !important;
        font-size: 20px ;
        letter-spacing: 0px;
        color: #393939;
        opacity: 1;
        text-align: center !important;
      
    }
}

.read-m-l{
    display: flex;
    justify-content: end;
}