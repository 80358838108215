@import "../../mixins.scss";


.treatment{
    display: flex;
    justify-content: center;
    align-items: center;
}
.box{
    width: 92px;
    height: 60px;
    box-shadow: 2px 2px 2px;
    margin: 30px;
    border-radius: 10px;
}
.flex-table{
    display: flex;
    justify-content: center;
}



@media screen and (max-width: 768px) {
    .box{
        width: 160px;
        height: 90px;
        box-shadow: 2px 2px 2px;
        margin: 30px;
        border-radius: 10px;
    }
}

.card-position-flex{
    display: flex;
    justify-content: center;
    align-items: center;

}

.card-position-flex-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.row-flex{
    display: flex;
    flex-direction: row !important;
     justify-content: space-around;
     align-items: center;
     padding-top: 20px;
     text-align: center;
   }
   
.card-test-money{

width: 156px ;
height: 125px !important;
border-radius: 10px;
box-shadow: 1px 1px 9px 1px #d2c8c8;
background-color: #FFFF;
margin: 20px;
}



.card-top-part-money{
    height: 101px;
    text-align: center;
}
.card-bottom-part-money{
    height: 33px;
    
    border-radius:0px 0px 10px 10px;
}

.icons-money{
    width: 60px;
    height: 60px;
}

.bg-money-treatment{
    background-color:#EDF8FD  ;
    padding-bottom: 20px;
    padding-top: 10px;
}

.heading-treatment{
    font:  medium;
    font-family: "Roboto";
    font-size: 42px;
    letter-spacing: 0px;
    color: #393939;
   
    text-transform: capitalize;
    opacity: 1;
}

.card-text-treatment-list{
    font-family: "Roboto";
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
}
.card-test-money-mobile{

    width: 165px !important;
    height: 165px !important;
    border-radius: 10px;
    box-shadow: 1px 1px 9px 1px #d2c8c8;
    background-color: #FFFF;
    margin: 20px;

    @include MQ(sm) {
        margin: auto;
    }
    }


    .headindtext-second-money{
        text-align: left;
        font-family: "Roboto";
        font-size: 42px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #393939;
        opacity: 1;
    }
.icons-rr{
 
        width: 48px;
        height: 46px;
        // margin-top: 10px;
        // margin-bottom: 10px;
        // margin-left: 36px;
  
}  

.headindtext-second-money-u {
    text-align: left;
    font-family: "Roboto";
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    #mobile-treatment-card-mobile{
     display: none !important;
    }

}

@media screen and (max-width:768px) {
    #web-treatment-card-mobile{
     display: none!important;   
 }
 .card-test-money{

    width: 156px ;
    height: 170px !important;
    border-radius: 10px;
    box-shadow: 1px 1px 9px 1px #d2c8c8;
    background-color: #FFFF;
    margin: 20px;
    }
    .headindtext-second-money-u{
        font-size: 24px !important;
        color: #393939 !important;
    }
    
}



#mobile-treatment-card-mobile{
      
    .carousel-indicators {
        position: absolute;
        bottom: -22px;
    

        [data-bs-target] {
            box-shadow: none;
            width: 20px;
            height: 5px;
             border-radius: 5px;
            background-color: grey;
            border: 1px solid grey;
        }

        .active {
            background-color: #52BAE6 ;
            border: 1px solid  #52BAE6;
        }
    }
}