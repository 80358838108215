#Termofuse {
  .heading-term {
    text-align: left;
    font: normal normal bold 42px/50px Roboto;
    letter-spacing: 0px;
    color: #393939;
    text-transform: capitalize;
    opacity: 1;
  }
  .web-text {
    text-align: left;
    font: normal normal normal 16px/24px Roboto;
    letter-spacing: 0px;
    color: #393939;
  }
  @media screen and (max-width: 572px) {
    .text-mobile {
      text-align: center;
    }
    .web-text {
      text-align: center;
      font: normal normal normal 14px/20px Roboto;
      letter-spacing: 0px;
      color: #393939;
    }
  }
}
