@import "../../mixins.scss";

.card-test {
    border-radius: 10px;
    box-shadow: 0px 0px 10px #00000029;
    width: 300px;
    height: 272px;
    margin: 20px;
    background-color: #FFFF;

}

.flex-card {
    display: flex !important;
    justify-content: center;
    align-items: center;

}

.card-top-part {
    height: 202px;
    text-align: left;
    padding: 15px;
    font-size: 14px;
    min-height: 167px;
}

.card-bottom-part {
    background-color: #52BAE6;
    border-radius: 0 0 12px 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 18px;
    justify-content: space-around;
}

.row-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.row {
    justify-items: self-end;
}

.testimonialBg {
    background-color: #EDF8FD;
}

.testimonial-small-header {

    font-size: 20px;
    font-weight: bold;
    color: #1E75BB;
    text-transform: capitalize;
    text-align: center;

    @include MQ(sm) {
        font-size: 16px;
    }
}


.testimonial-small-header-second {
    font-size: 42px;
    font-weight: bold;
    color: #393939;
    text-transform: capitalize;
    text-align: center;

    @include MQ(sm) {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) {
    #mobile-testimonial {
        display: none;
    }

}

@media screen and (max-width:768px) {
    .hideInMobile {
        display: none !important;
    }
}

@media screen and (width: 360px) {

    .card-test-mobile{
     margin-left: 33px !important;
    }
 }
 

.card-test-mobile {
    border-radius: 10px;
    box-shadow: 0px 0px 10px #00000029;
    width: 300px;
    //  margin: 20px;
    margin-left: 57px;
    background-color: white;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 12px;

}

.quate-design {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.mobile-testimonial {
    .carousel-indicators {
        bottom: -30px;

        [data-bs-target] {
            box-shadow: none;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: grey;
            border: 1px solid grey;
        }

        .active {
            background-color: #52BAE6;
            border: 1px solid #52BAE6;
        }
    }
}



@media screen and (max-width: 380px) {

    .card-test-mobile{
     margin-left: 33px !important;
    }
 }