#aboutTwo {
  .text {
    text-align: center;
  }

  .right-pic-main-about {
    // height: 320px;
    width: 100%;
    position: absolute;
    max-width: 400px;
    top: 0;
    // right: 5px;
    z-index: 2;
    left: 0;
  }

  .left-pic-main-dots-about {
    /* height: 235px; */
    max-width: 235px;
    position: absolute;
    top: -35px;
    /* right: 130px; */
    z-index: 1;
    left: 12rem;
    width: 100%;
  }

  .left-pic-main-about {
    height: 221px;
    width: 320px;
    position: relative;
    top: 34px;
    right: -143px;
    z-index: 2;
  }

  .right-pic-main-dots-about {
    height: 235px;
    width: 235px;
    position: relative;
    top: -145px;
    left: 100px;
  }

  .small-heading {
    font: "Roboto";
    font-size: 20px;
    letter-spacing: 0px;
    color: #1e75bb;
    font-weight: bolder;
    margin-top: 21px;
  }
  .small-heading-second {
    font: "italic";
    font-size: 42px;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
    font-weight: bolder !important;
    line-height: normal;
    font-weight: bold;
    text-align: left;
  }
  .paragrap-pic {
    font: "Roboto";
    font-size: 18px;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
  }
  .solidc {
    position: absolute;
    /* left: 416px; */
    /* top: 0; */
    z-index: 2;
    left: 409px;
    bottom: -28px;
    max-width: 46px;
  }
  .hollowa {
    width: 100%;
    max-width: 31px;
    position: absolute;
    /* bottom: 176px; */
    left: 85px !important;
    top: 57px !important;
  }
  .solidb {
    width: 100%;
    max-width: 51px;
    position: absolute;
    left: 127px;
    /* bottom: 60px; */
    top: 47px;
  }
  @media screen and (max-width: 760px) {
    .margin-t-t {
      margin-top: 33px !important;
    }
    
    .left-pic-main-dots-about {
      height: 150px !important;
      width: 150px !important;
      position: absolute !important;
      top: 22px !important;
      left: 133px !important;
      z-index: 1 !important;
    }
    .small-heading {
      font: "Roboto";
      font-size: 20px;
      letter-spacing: 0px;
      color: #1e75bb;
      font-weight: bolder;
      text-align: center;
    }
    .small-heading-second {
      font: "Roboto";
      font-size: 28px;
      letter-spacing: 0px;
      color: #393939;
      opacity: 1;
      font-weight: bolder;
      text-align: center;
      display: none;
    }
    .paragrap-pic {
      font: "Roboto";
      font-size: 18px;
      letter-spacing: 0px;
      color: #393939;
      opacity: 1;
      text-align: center;
    }

    .left-pic-main-dots-about-cat {
      width: 247px !important;
      height: 247px !important;
      right: 80px !important;
      bottom: 16px !important;
    }

  
    .right-pic-main-dots-about-belove {
      height: 155px !important;
      width: 155px !important;
      position: absolute !important;
      top: 234px !important;
      left: 14px !important;
    }

    .right-pic-main-dots-about-above {
      height: 148px !important;
      width: 148px !important;
      position: absolute !important;
      top: 7px !important;
      left: 155px !important;
    }
    .about-card-shadow {
      position: absolute !important;
      width: 247px !important;
      top: 40px !important;
      left: 18px !important;
    }
    .about-card-shadow2 {
      width: 239px !important;
      height: 228px !important;
      position: absolute !important;
      top: 144px !important;
      left: 33px !important;
    }
    .hollowa {
      width: 100%;
      max-width: 29px;
      position: absolute;
      /* bottom: 176px; */
      left: 58px !important;
      top: 60px !important;
    }
    .solidb {
      width: 100%;
      max-width: 42px;
      position: absolute;
      left: 96px;
      /* bottom: 60px; */
      top: 51px;
    }
    .solidc {
      width: 100%;
      max-width: 40px;
      position: absolute;
      left: 273px;
      bottom: 43px;
    }
  }

  .left-pic-main-dots-about-cat {
    max-width: 400px;
    /* height: 391px; */
    position: absolute;
    top: 119px;
    left: 40px;
    z-index: 1;
    width: 100%;
  }
  .left-pic-main-dots-about-lady {
    max-width: 472px;
    position: absolute;
    /* top: -41px; */
    /* left: 239px; */
    z-index: 1;
    width: 100%;
    object-fit: cover;
  }

  .right-pic-main-dots-about-belove {
    max-width: 251px;
    position: absolute;
    top: 295px;
    left: 0;
    width: 100%;
  }

  .right-pic-main-dots-about-above {
    max-width: 240px;
    position: absolute;
    top: -70px;
    left: 209px;
    width: 100%;
    object-fit: cover;
  }

  .about-card-shadow {
    position: absolute;
    max-width: 390px;
    top: -7px;
    left: -10px;
    width: 100%;
    object-fit: cover;
  }
  .about-card-shadow2 {
    width: 386px;
    height: 375px;
    position: absolute;
    top: 144px;
    left: 30px;
  }

  @media only screen and (max-width: 1440px) and (min-width: 1024px) {
    .about-card-shadow2 {
      max-width: 298px !important;
      /* height: 375px; */
      top: 144px;
      left: 30px;
      width: 100% !important;
      height: 100% !important;
      max-height: 283px !important;
    }
    .right-pic-main-dots-about-belove {
      max-width: 158px !important;
      top: 295px !important;
      left: 0;
      width: 100%;
    }
    
    .right-pic-main-dots-about-above {
      max-width: 130px;
    }
    .small-heading-second {
      font: "italic";
      font-size: 28px !important;
    }
    .paragrap-pic {
      font: "Roboto";
      font-size: 16px;
    }
    .left-pic-main-dots-about-cat {
      max-width: 305px !important;
    }
    .left-pic-main-dots-about-lady {
      top: 0 !important;
    }
    .right-pic-main-dots-about-above {
      max-width: 135px;
      position: absolute;
      top: -20px;
      left: 219px;
      width: 100%;
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 360px) and (min-width: 360px) {
    .left-pic-main-dots-about-cat {
      left: 6px;
    }
    .about-card-shadow2 {
      left: 0 !important;
    }
    .right-pic-main-dots-about-above {
      left: 137px !important;
    }
    .right-pic-main-dots-about-belove {
      position: absolute !important;
      top: 235px !important;
      left: -17px !important;
    }
  }
}
