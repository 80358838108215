.first-card-impact{
    background-color: #FFFF;
    height: 460px;
    display: flex;
    justify-content:center;
    font-size: 30px;
    flex-direction: column;
}
.second-card-impact{
 
    height: 460px;
}

.img-first-section-main{
    width: 283px;
    height: 327px;
    position: relative;
    top: 96px;
    left: 40px;
}

.img-first-section-dots{
    width: 250px;
    height: 327px;
    position: relative;
    top: -325px;
    right: -96px;
    z-index: -1;
}

.headindtext-first-impact{
    text-align: left;
    font-family: "Roboto";
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
}


.hb-overlay1{
    background-color: #FFFF !important;
}

.hb-btn1{
     background-color: #1e75bb !important; 
     color: #FFFF !important;
}


.hb-btn1:hover{
  color: #ffff !important;
}

.hb-content-matrix1{
    background-color: #52BAE6 !important;
    color: #FFFF !important;
    border-width: 3px;
    border-color: #ffff;
    border-style: ridge
}

.hb-content-matrix-header1{
    color: #FFFF !important;
}
.hb-content-matrix-content1{
 color: #FFFF !important;
}

.ractangle{
    width: 319px;
height: 428px;
}



.hba {
    height: 700px;
    position: relative;
    overflow: hidden;
  
    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #1E75BB;
      opacity: .75;
    }
  
    &-left {
      display: flex;
      align-items: left;
      justify-content: center;
      flex-direction: column;
  
  
      &-header {
        color: white;
        font-weight: bold;
        font-size: 42px;
  
        &-main {
          font-size: 50px;
          line-height: 60px;
          margin-bottom: 20px;
        }
      }
    }
  
    &-content {
      position: relative;
      z-index: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .row {
        width: 100%;
      }
  
  
      &-right {
        display: flex;
        align-items: center;
        justify-content: right;
        height: 100%;
        position: relative;
      }
  
      &-matrix {
        position: absolute;
        right: 350px;
        padding: 1rem;
        border-radius: 12px;
        bottom: 40px;
        background-color: white;
        z-index: 1;
        box-shadow: 0px 3px 6px #00000029;
        text-align: center;
        width: 180px;
  
        &-header {
          font-size: 29px;
          color: #393939;
          font-weight: bold;
  
          &:not(:first-child) {
            margin-top: 1rem;
          }
        }
  
        &-content {
          color: #707070;
          font-size: 19px;
          margin-bottom: 0;
        }
      }
  
      &-img {
        width: 400px;
        height: 500px;
        object-fit: cover;
        border-radius: 12px;
  
      }
    }
  
    &-btn {
      margin-top: 20px;
      background: white;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 12px;
      font-weight: bold;
      font-size: 20px;
      color: hsl(204, 26%, 96%);
      padding: 14px 30px;
      text-decoration: none;
    }
  }
  