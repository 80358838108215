@import "../../mixins.scss";

.br-banner-size {
    height: 434px;
    background-image: url('./images/Mask\ Group\ 141.png');
    background-color: blue;
    text-align: center;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;

    @include MQ(sm) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: -100px;
        height: 200px;
    }

}


.text-positions{
    position: relative !important;
    top:40px !important;
    text-align: center !important;
    color: #ffff !important;
}

.banner-heading{
    font-size: 42px;
    font-weight: bold;
}

.revert-homepgae{
    font-size:24px ;
    width: 200px;
height: 29px;
} 

@media screen and (max-width: 768px) {

    .banner-heading{
        font-size: 24px !important;
        font-weight: bold;
    }
    .revert-homepgae{
        font-size:18px ;
        width: 200px;
    height: 29px;
    } 
    
}

.linkabt{
    color: white !important;
    text-decoration: none !important;
}