@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,900;1,100&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.px-20-f {
  font-size: 20px;
  font-family: "Roboto";
  color: #393939;
  font-weight: bold;
}

.px-16-f {
  font-size: 16px;
  font-family: "Roboto";
  color: #393939;
}

.c-nav {
  position: sticky !important;
  z-index: 4;
  width: 100%;
  background-color: #ffffff !important;
}

@media screen and (max-width: 768px) {
  .reverse-in-mobile {
    flex-direction: column-reverse !important;
  }
  .revers-text-box {
    text-align: center !important;
  }
  .px-24 {
    font-size: 24px !important;
  }
  .fl-reves-succse {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .headindtext-first {
    font-size: 24px !important;
    font-weight: bold;
  }

  .revers-text-box-sucess-card {
    text-align: center !important;
    margin-left: 15px !important;
    margin-bottom: 18px !important;
  }

  .img-first-secure-dots {
    width: 250px !important;
    height: 327px !important;
    position: relative !important;
    top: -323px !important;
    left: 79px !important;
    z-index: 1 !important;
  }
  .img-first-secure-main {
    width: 241px !important;
    height: 316px !important;
    position: relative !important;
    top: 65px !important;
    left: 54px !important;
    z-index: 2;
  }
  .headindtext-first {
    bottom: 173px !important;
    position: relative;
  }
}

.postion-relative-block {
  position: relative;
}

@media screen and (max-width: 768px) {
  
  .logo {
    width: 8rem;
  }
  .img-icon {
    width: 45px !important;
    height: 38px !important;
  }

  /* .left-pic-main-money {
    height: 198px !important;
    width: 253px !important;
    position: absolute !important;
    top: 0 !important;
    left: 31px !important;
    z-index: 2 !important;
  } */
  .left-pic-main-dots-money {
    height: 145px !important;
    width: 145px !important;
    position: absolute !important;
    top: -25px !important;
    left: 162px !important;
    z-index: 1 !important;
  }
  .left-pic-main-secure {
    height: 189px !important;
    width: 253px !important;
    position: absolute !important;
    top: -57px !important;
    left: 34px !important;
    z-index: 2 !important;
  }
  .left-pic-main-dots-secure {
    height: 145px !important;
    width: 145px !important;
    position: absolute !important;
    top: -77px !important;
    left: 161px !important;
    z-index: 1 !important;
  }
  .right-pic-main-secure {
    height: 200px !important;
    width: 254px !important;
    position: absolute !important;
    top: 70px !important;
    /* right: 68px !important; */
    z-index: 2 !important;
    left: 78px !important;
  }
  .right-pic-main-dots-secure {
    height: 145px !important;
    width: 145px !important;
    position: absolute !important;
    top: 46px !important;
    left: 56px !important;
    z-index: 1 !important;
  }
  .dots-align {
    position: absolute !important;
    top: -26px !important;
    right: -27px !important;
    z-index: -1 !important;
    max-width: 195px;
    max-width: 194px !important;
  }

  .circle-rotate {
    bottom: 56px;
    right: 17px !important;
  }
  .arrow {
    position: fixed;
    bottom: 58px;
    right: 18px !important;
    font-size: 51px;
    width: 36px;
    border-radius: 27px;
    background-color: #1c75bc;
    z-index: 5;
  }

  .circle1 {
    max-width: 29px !important;
    top: 9rem !important;
    left: -0.5rem !important;
  }
  .circle-rotate {
    position: fixed;
    bottom: 50px;
    right: 11px !important;
    height: 50px;
    width: 100%px;
    font-size: 50px;
    z-index: 5;
  }

  .shadow-back-main {
    width: 60vw !important;
    height: 100%;
  }
  .top-hollow {
    max-width: 29px !important;
    top: 7rem !important;
    left: -2.5rem !important;
  }
  .hollow1 {
    max-width: 29px !important;
    top: 7rem !important;
    left: -2.5rem !important;
  }
  .top-solid {
    max-width: 23px !important;
    top: 9.5rem !important;
    left: -1.5rem !important;
  }
  .top-circle {
    max-width: 29px !important;
    top: 19rem !important;
    left: -1.5rem !important;
    height: 31px;
  }
}

.color-text {
  color: white !important;
}
/* mobile header */
#offcanvasNavbar-expand-sm {
  visibility: visible;
  width: 321px;
  border-radius: 22px 0px 0px 15px;
}

/* scroll bar css */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03649d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03649d;
}

::-webkit-scrollbar:horizontal {
  background: #03649d;
  width: 10px !important;
  border-radius: 10px;
}

/* end of scroll bar css */

/* scroll top */
.rotate {
  transform: rotate(-90deg) !important;
}
.circle-rotate {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 50px;
  width: 100%px;
  font-size: 50px;
  z-index: 5;
}
.arrow {
  position: fixed;
  bottom: 58px;
  right: 56px;
  font-size: 51px;
  width: 36px;
  border-radius: 27px;
  background-color: #1c75bc;
  z-index: 5;
}

.c-nav .navbar-toggler {
  border: none;
}
.navbar-toggler-icon {
  /* background-image: ; */
}

@media screen and (max-width: 700px) {
  .offcanvas-body {
    border-top: #898585;
    border-style: ridge;
    border-width: 1px 0px 0px 0px;
  }
}