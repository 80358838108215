.first-right-part{
  
    height: 460px;
    display: flex;
    justify-content:center;
 flex-direction: column;
  
}
.second-left-part{

    height: 460px;
}

.img-first-section-main{
    width: 277px;
    height: 327px;
    position: relative;
    top: 96px;
    left: 40px;
}

.img-first-section-dots{
    width: 250px;
    height: 327px;
    position: relative;
    top: -325px;
    right: -96px;
    z-index: -1;
}

.headindtext-first-money{
    text-align: left;
    font-family: "Roboto";
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #393939;
    opacity: 1;
}

.hb-overlay1{
    background-color: #FFFF !important;
}

.hb-btn1{
     background-color: #1e75bb !important; 
     color: #FFFF !important;
}
.hb-content-matrix1{
    background-color: #52BAE6 !important;
    color: #FFFF !important;
    border-width: 3px;
    border-color: #ffff;
    border-style: ridge
}

.hb-content-matrix-header1{
    color: #FFFF !important;
}
.hb-content-matrix-content1{
 color: #FFFF !important;
}


@media screen and (max-width : 768px) {

    
}